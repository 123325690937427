import React from 'react';

const FeedPlan = (props) => {
    return (
        <>
        </>
    );
};

export default FeedPlan;
