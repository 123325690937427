import React, {useState} from 'react';
import {isMobileOnly} from 'react-device-detect';


import {Button, Dialog, Grid, Paper, Typography} from '@material-ui/core';
import {withStyles} from '@material-ui/core/styles';

import FeedStallDetail from './FeedStallDetail';
import Home from '../pages/Home';

const useStyles = {
    paper: {
        width: "100%",
        height: 180,
        minWidth: 192,
    },
    button: {
        padding: 0,
        "& p": {
            textTransform: "none",
        },
        '@media (min-width: 616px)': {
            maxWidth: 192,
        },
    },
    padR16: {
        paddingRight: 16,
    },
    deviceStatus: {
        width: 8,
        height: 36,
        borderTopLeftRadius: 4,
        marginRight: 8,
    },
    stallNo: {
        fontSize: 24,
        fontWeight: 700,
        '@media (max-width: 615px)': {
            fontSize: 30,
        },
    },
    stallStatus: {
        fontSize: 16,
        '@media (max-width: 615px)': {
            fontSize: 18,
        },
    },
    deviceNo: {
        fontSize: 16,
        color: "#828282",
        '@media (max-width: 615px)': {
            fontSize: 18,
        },
    },
    container: {
        padding: "0 12px",
        "& p": {
            fontSize: 13,
            '@media (max-width: 615px)': {
                fontSize: 15,
            },
        },
    },
    containerLast: {
        marginTop: 10,
        padding: "0 12px",
        "& p": {
            fontSize: 13,
            '@media (max-width: 615px)': {
                fontSize: 15,
            },
        },
    },
    bar: {
        backgroundColor: "#ebebf3",
        borderRadius: 4,
        "& div": {
            backgroundColor: "#8eb5ff",
            height: 8,
            borderRadius: 4,
        },
    },
    feedBox: {
        backgroundColor: "#fbfbfd",
        height: 50,
        "& > div": {
            borderRight: "solid 1px #ebebf3",
        },
        "& > div:last-child": {
            borderRight: 0,
        },
    },
};

// locale 작업하기
const FeedInfoCard = (props) => {
    const {aFeedInfo, classes, maxItems, stallNums} = props;

    const [dialogOpen, setDialogOpen] = useState(false);

    const beforeLatest = aFeedInfo.hasOwnProperty("feed_before_latest") ?
        aFeedInfo.feed_before_latest[0].intake_amount / aFeedInfo.feed_before_latest[0].feed_plan_amount * 100 + "%"
        :
        "0%";
    const beforeLatestTotal = aFeedInfo.hasOwnProperty("feed_before_latest") ?
        (aFeedInfo.feed_before_latest[0].intake_amount / 1000).toFixed(1) + " / "
        + (aFeedInfo.feed_before_latest[0].feed_plan_amount / 1000).toFixed(1) + "kg"
        :
        "없음";
    const latest = aFeedInfo.feed_latest[0].intake_amount / aFeedInfo.feed_latest[0].feed_plan_amount * 100 + "%";
    const latestTotal = aFeedInfo.stall_state !== 1 ? (aFeedInfo.feed_latest[0].intake_amount / 1000).toFixed(1) + " / "
        + (aFeedInfo.feed_latest[0].feed_plan_amount / 1000).toFixed(1) + "kg"
        : "없음";
    const stallState = {
        1: "빈방",
        2: "입식",
        3: "분만",
    };
    const deviceState = {
        1: "#3070AC",
        2: "#3070AC",
        3: "#3070AC",
        4: "#DE3234",
        5: "#DE3234",
        6: "#DE3234",
        7: "#DE3234",
    };
    const feedLengthCheck = (aFeedInfo.feed_latest.length >= 2);

    return (
        <>
            <Button onClick={(e) => setDialogOpen(true)} fullWidth={true} className={classes.button}
                    style={{boxShadow: "0px 2px 1px -1px rgba(0,0,0,0.2),0px 1px 1px 0px rgba(0,0,0,0.14),0px 1px 3px 0px rgba(0,0,0,0.12)"}}>
                <Paper className={classes.paper} elevation={0}>
                    <Grid container item xs={12} alignItems={"baseline"} className={classes.padR16}>
                        <Grid item className={classes.deviceStatus}
                              style={{backgroundColor: deviceState[aFeedInfo.device_status]}}>{""}</Grid>
                        <Grid container item xs={3}>
                            <Typography className={classes.stallNo}>
                                {aFeedInfo.stall_no}
                            </Typography>
                        </Grid>
                        <Grid container item xs={5} justify={"flex-start"}>
                            <Typography className={classes.stallStatus}>
                                {aFeedInfo.stall_state === 1 ? "빈방" : stallState[aFeedInfo.stall_state] + " / " + aFeedInfo.latest_process_day}
                            </Typography>
                        </Grid>
                        <Grid container item xs justify={"flex-end"}>
                            <Typography className={classes.deviceNo}>
                                {aFeedInfo.r_code + "-" + aFeedInfo.s_code}
                            </Typography>
                        </Grid>
                    </Grid>
                    <Grid container item xs={12} className={classes.container}>
                        <Grid item xs={12}>
                            <Typography align={"left"}>
                                어제: {beforeLatestTotal}
                            </Typography>
                        </Grid>
                    </Grid>
                    <Grid container item xs={12} className={classes.container}>
                        <Grid container item xs={12} className={classes.bar}>
                            <Grid item style={{width: beforeLatest}}/>
                        </Grid>
                    </Grid>
                    <Grid container className={classes.container}>
                        <Grid item xs={12}>
                            <Typography align={"left"}>
                                오늘: {latestTotal}
                            </Typography>
                        </Grid>
                    </Grid>
                    <Grid container item xs={12} className={classes.container}>
                        <Grid container item xs={12} className={classes.bar}>
                            <Grid item style={{width: latest}}/>
                        </Grid>
                    </Grid>
                    <Grid container className={classes.containerLast}>
                        <Grid container item xs={2} alignItems={"center"}>
                            <Grid container item xs>
                                <Grid item xs={12}><Typography align={"center"}>목표</Typography></Grid>
                                <Grid item xs={12}><Typography align={"center"}>섭취</Typography></Grid>
                            </Grid>
                        </Grid>
                        <Grid container item xs className={classes.feedBox} alignItems={"center"}>
                            {feedLengthCheck ?
                                Array(aFeedInfo.feed_latest.length - 1).fill(0).map((value, index) => {
                                    const idx = index + 1;
                                    const feedUnit = aFeedInfo.feed_unit;
                                    const aFeedLatest = aFeedInfo.feed_latest[idx];

                                    return (
                                        <Grid container item key={idx} xs>
                                            <Grid item xs={12}>
                                                <Typography align={"center"}>
                                                    {aFeedLatest.target_times}
                                                    {/*{Math.ceil(aFeedLatest.feed_plan_amount / feedUnit)}*/}
                                                </Typography>
                                            </Grid>
                                            <Grid item xs={12}>
                                                <Typography align={"center"}>
                                                    {aFeedLatest.intake_times}
                                                    {/*{Math.ceil(aFeedLatest.intake_amount / feedUnit)}*/}
                                                </Typography>
                                            </Grid>
                                        </Grid>
                                    )
                                })
                                :
                                null
                            }
                        </Grid>
                    </Grid>
                </Paper>
            </Button>
            <Dialog fullScreen={isMobileOnly}
                    maxWidth={isMobileOnly ? false : 'md'}
                    open={dialogOpen}
                    aria-labelledby="max-width-dialog-title">
                <FeedStallDetail
                    closeStallDialog={setDialogOpen(false)}
                    selectedStallNo={aFeedInfo.stall_no}
                    stallTotalCount={maxItems}
                    stalls={stallNums}
                    reloadParentData={props.reloadParentData}
                    bcsInfo={props.bcsInfo}
                    autoConvertMode={props.autoConvertMode}/>
            </Dialog>
        </>
    );
};

export default withStyles(useStyles)(FeedInfoCard);
