import * as types from '../actions/ActionTypes';

const initialState = {
    admin: false,
    farmCode: undefined,
    pigstyCode: undefined,
    userID: undefined
};

const auth = (state = initialState, action) => {
    switch (action.type) {
        case types.FETCH_AUTH:
            return {...state};
        case types.FETCH_AUTH_FAILED:
            return {...initialState};
        case types.FETCH_AUTH_SUCCESS:
            return {...state, ...action.payload};
        case types.REVOKE_AUTH:
            return {
                ...state,
                ...initialState
            };
        case types.SET_AUTH:
            return {
                ...state,
                ...action.auth
            };
        default:
            return state;
    }
};

export default auth;
