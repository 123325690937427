import * as types from './ActionTypes';

// Alert
export const closeAlert = () => ({
    type: types.CLOSE_ALERT
});
export const openAlert = (alert) => ({
    alert: alert,
    type: types.OPEN_ALERT
});

// Auth
export const fetchAuth = (payload) => ({
    payload: payload,
    type: types.FETCH_AUTH
});

export const revokeAuth = () => ({
    type: types.REVOKE_AUTH
});

export const setAuth = (auth) => ({
    type: types.SET_AUTH,
    auth: auth
});