import React, {useEffect, useState} from 'react';
import {isMobile, isMobileOnly} from 'react-device-detect';
import {FomattedMessage} from 'react-intl';
import {connect} from 'react-redux';

import {Dialog, Grid} from '@material-ui/core';

import FeedStallDetail from '../common/FeedStallDetail';
import Home from './Home';

import fetchRest from '../util/fetch';

const FeedStall = (props) => {
    const {auth} = props;

    const [autoConvertMode, setAutoConvertMode] = useState(1);
    const [bcsInfo, setBcsInfo] = useState(undefined);
    const [rCode, setRCode] = useState(0);
    const [selectedStallNo, setSelectedStallNo] = useState(undefined);
    const [stallDialog, setStallDialog] = useState(false);
    const [stalls, setStalls] = useState([]);
    const [totalCount, setTotalCount] = useState(undefined);
    const [typeTable, setTypeTable] = useState('detail');

    useEffect(function () {
        // const auth = props.auth;
        fetchRest(`/farm_settings_info?farm_code=${auth.farmCode}&pigsty_code${auth.pigstyCode}`)
            .then(data => {
                setAutoConvertMode(data['auto_convert_mode']);
                setBcsInfo(data['BCS']);
                setRCode(parseInt(data['r_code']));
            });
    }, []);

    useEffect(function () {
        typeTable === 'reload' && setTypeTable('done');
    }, [typeTable]);

    const getTotalRow = (rows, totalCount, stalls) => {
        setStalls(stalls);
        setTotalCount(parseInt(totalCount));
    };

    return (
        (isMobileOnly && (window.navigator.userAgent.match(/Samsung SM-P200/i) === null))
            ? <Home/>
            : <>
                <Grid container justify='center'>
                    {
                        typeTable !== 'reload'
                        && <Grid item md={10} sm={11}>
                            {/*테이블 작업 끝내고 마저 하기*/}
                            {/*<FeedStallTable R_CODE={rCode}*/}
                            {/*                modalOpen={setStallDialog(true)}*/}
                            {/*                getTotalRow={this.getTotalRow}/>*/}
                        </Grid>
                    }
                </Grid>
                <Dialog
                    fullScreen={isMobile}
                    maxWidth={isMobile ? false : 'md'}
                    open={stallDialog}
                    aria-labelledby="max-width-dialog-title">
                    <FeedStallDetail
                        closeStallDialog={setStallDialog(false)}
                        selectedStallNo={selectedStallNo}
                        stallTotalCount={totalCount}
                        stalls={stalls}
                        reloadParentData={setTypeTable('reload')}
                        bcsInfo={bcsInfo}
                        autoConvertMode={autoConvertMode}
                    />
                </Dialog>
            </>
    );
};

const mapStateToProps = (state) => ({
    auth: state.auth
});

export default connect(mapStateToProps)(FeedStall);
