import React from 'react';

const FeedStallDetail = (props) => {
    return (
        <>
        </>
    );
};

export default FeedStallDetail;
