import React, {useEffect} from 'react';
import {IntlProvider} from 'react-intl';
import {connect} from 'react-redux';
import {Route, Router, Switch} from 'react-router-dom';

import {Grid} from '@material-ui/core';

import AlertDialog from './common/AlertDialog';
import AppBarEco from './common/AppBarEco';

import CreateUser from './pages/CreateUser';
import FeedPlan from './pages/FeedPlan';
import FeedStall from './pages/FeedStall';
import FeedStallDetail from './pages/FeedStallDetail';
import FeedTime from './pages/FeedTime';
import Home from './pages/Home';
import LogIn from './pages/LogIn';

import history from './history';
import logo from './logo.svg';
import './App.css';

// locale
import ko from './locale/ko';

const defaultLang = 'ko';
const locale = {ko: ko};

const App = (props) => {
    // useEffect(function () {
    // 토큰이 없으면 로그인 페이지로
    // if (history.location.pathname === '/login') {
    //     return;
    // }
    // else if (!props.auth || ) {
    //
    // }
    // if (history.location.pathname !== '/login' || !props.auth || !props.auth.farmCode) {
    //     history.push('/login');
    //     console.log('test');
    //     console.log(history.location);
    // }
    // }, []);

    return (
        <IntlProvider locale='ko' messages={locale['ko']}>
            <Grid container direction='column'>
                {/*상단 바*/}
                <Grid item>
                    <AppBarEco/>
                </Grid>
                {/* 메인 패널*/}
                <Grid item>
                    {
                        props.auth && props.auth.farmCode
                            ? <Router history={history}>
                                <Switch key={history.location.key}>
                                    {/*{*/}
                                    {/*    // props.auth.farmCode &&*/}
                                    {/*<>*/}
                                    <Route component={CreateUser} path='/createuser'/>
                                    <Route component={FeedPlan} path='/feed_plan'/>
                                    <Route component={FeedStall} path='/feed_stall'/>
                                    <Route component={FeedStallDetail} path='/feed_stall/:stallNo'/>
                                    <Route component={FeedTime} path='/feed_time'/>
                                    <Route component={Home} path='/'/>
                                    {/*</>*/}
                                    {/*}*/}
                                    <Route component={LogIn} path='/login'/>
                                </Switch>
                            </Router>
                            : <LogIn/>
                    }
                </Grid>
                <AlertDialog/>
            </Grid>
        </IntlProvider>
    );
};

const mapStateToProps = (state) => ({
    auth: state.auth
});

export default connect(mapStateToProps)(App);
