import history from '../history';
import merge from 'lodash/merge';

import {revokeAuth} from '../actions';
import {store} from '../store';

export function makeFetch(baseUrl = '', defaults) {
    return function fetch_(resource, init) {
        const overrideResource = typeof resource === 'string' ? baseUrl + resource : resource;
        let overrideInit = init || {};
        switch (typeof defaults) {
            case 'function':
                overrideInit = defaults(overrideInit);
                break;
            case 'object':
                overrideInit = merge(defaults, overrideInit);
                break;
        }
        return fetch(overrideResource, overrideInit);
    }
}

const CONTENT_TYPE = 'Content-Type';
const X_FARM_ID = 'x-farm-id';
const X_FARM_CODE = 'x-farm-code';

export const fetchAPI = makeFetch(
    process.env.REACT_APP_API_SERVER,
    init => {
        const headers = init.headers || {};
        if (!headers[CONTENT_TYPE]) {
            headers[CONTENT_TYPE] = 'application/json; charset=utf8';
        }
        if (!headers[X_FARM_ID] || !headers[X_FARM_CODE]) {
            const state = store.getState();
            headers[X_FARM_ID] = state.auth.userID;
            headers[X_FARM_CODE] = state.auth.farmCode;
        }
        return {...init, headers};
    }
);

export default function fetchRest(resource, init) {
    return fetchAPI(resource, init)
        .then(resp => {
            if (resp.status === 403) {
                store.dispatch(revokeAuth());
                history.push('/');
                return Promise.reject(resp);
            }
            return resp.json();
        })
};
