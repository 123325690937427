import React from 'react';
import {FormattedMessage} from 'react-intl';

import {Button, ButtonGroup} from '@material-ui/core';

const EditButtonGroup = (props) => {


    if (props.condition === 'edit') {
        return (
            <ButtonGroup>
                <Button onClick={props.editData} size={props.buttonSize}>
                    <FormattedMessage id='commonEdit'/>
                </Button>
            </ButtonGroup>
        );
    } else if (props.condition === 'additional') {
        return (
            <ButtonGroup>
                <Button onClick={props.editData} size={props.buttonSize}>
                    <FormattedMessage id='commonEdit'/>
                </Button>
                <Button onClick={props.additional} size={props.buttonSize}>
                    {props.additionalText}
                </Button>
            </ButtonGroup>
        );
    } else if (props.condition === 'editDone') {
        return (
            <ButtonGroup>
                <Button onClick={props.editCancel} size={props.buttonSize}>
                    <FormattedMessage id='commonCancle'/>
                </Button>
                <Button onClick={props.updateData} size={props.buttonSize}>
                    <FormattedMessage id='commmonComplete'/>
                </Button>
            </ButtonGroup>
        );
    }
};

export default EditButtonGroup;
