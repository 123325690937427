import React, {useEffect, useState} from 'react';
import {isMobile, isMobileOnly} from 'react-device-detect';
import {connect} from 'react-redux'

import {FormControl, Grid, MenuItem, Select, Typography} from '@material-ui/core';
import {withStyles} from '@material-ui/core/styles';

import FeedInfoCard from '../common/FeedInfoCard';
import Pagination from '../common/Pagination';

import fetchRest from '../util/fetch';

const useStyles = {
    container: {
        maxWidth: 1200,
        marginTop: '20px',
        "@media (min-width: 2400px)": {
            maxWidth: 1500,
            marginTop: '10px',
        },
        "@media (max-width: 1208px)": {
            maxWidth: 1000,
            marginTop: '10px',
        },
        "@media (max-width: 1008px)": {
            maxWidth: 808,
            marginTop: '10px',
        },
        "@media (max-width: 808px)": {
            maxWidth: 616,
            marginTop: '10px',
        }
    },
};

const Home = (props) => {
    const {auth, classes} = props;

    const [dataFetched, setDataFetched] = useState(false);
    const [page, setPage] = useState(1);

    const [autoConvertMode, setAutoConvertMode] = useState(undefined);
    const [bcsInfo, setBcsInfo] = useState(undefined);
    const [feedInfo, setFeedInfo] = useState({});
    const [maxItems, setMaxItems] = useState(0);
    const [maxPage, setMaxPage] = useState(1);
    const [pageNumberEnd, setPageNumberEnd] = useState(undefined);
    const [pageNumberStart, setPageNumberStart] = useState(undefined);
    const [stallNums, setStallNums] = useState(undefined);

    useEffect(function () {
        loadData();
    }, []);

    const onButtonBefore = () => {
        page !== 1 && loadData(page - 1);
    };

    const onButtonNext = () => {
        page < maxPage && loadData(page + 1);
    };

    const onButtonPage = (page) => {
        loadData(page);
    };

    const loadData = (targetPage) => {
        targetPage = targetPage || page;
        // setDataFetched(false);
        fetchRest(`/feed_info?farm_code=${auth.farmCode}&pigsty_code=${auth.pigstyCode}&page=${targetPage}`)
            .then(data => {
                setAutoConvertMode(data['auto_convert_mode']);
                setBcsInfo(data['BCS']);
                setFeedInfo(data['feedInfo']);
                setMaxItems(data['maxItems']);
                setMaxPage(data['maxPage']);
                setPageNumberEnd(data['pageNumberEnd']);
                setPageNumberStart(data['pageNumberStart']);
                setStallNums([data['stallNums']]);
            })
            .then(() => {
                setPage(targetPage);
                setDataFetched(true);
            });
    };

    return (
        dataFetched ?
            <Grid container justify={"center"}>
                {
                    (isMobile || isMobileOnly) &&
                    <Grid item container
                          alignItems="center"
                          direction="row"
                          justify="flex-end">
                        <Typography style={{marginRight: '10px'}}>R 코드</Typography>
                        <FormControl margin='normal' style={{width: '120px', textAlign: 'center'}}>
                            <Select onChange={(e) => setPage(e.target.value)} value={page}>
                                {
                                    Array(maxPage).fill(1).map((value, index) => {
                                        const idx = index + 1;
                                        return <MenuItem key={`r${idx}`} value={idx}>{idx}</MenuItem>
                                    })
                                }
                            </Select>
                        </FormControl>
                    </Grid>
                }
                <Grid item container spacing={1} className={classes.container} justify={"flex-start"}>
                    {Object.keys(feedInfo).map((value) => {
                        const aFeedInfo = feedInfo[value];
                        return <Grid item xs={12} sm={"auto"} key={aFeedInfo.stall_no}>
                            <FeedInfoCard aFeedInfo={aFeedInfo}
                                          stallNums={stallNums}
                                          maxItems={maxItems}
                                          reloadParentData={loadData}
                                          bcsInfo={bcsInfo}
                                          autoConvertMode={autoConvertMode}/></Grid>
                    })}
                </Grid>
                <Grid item container style={{maxWidth: 1200}} xs={12}>
                    <Pagination
                        maxPage={maxPage}
                        page={page}
                        startItem={pageNumberStart + 1}
                        endItem={pageNumberEnd}
                        maxItems={maxItems}
                        onButtonBefore={onButtonBefore}
                        onButtonNext={onButtonNext}
                        onButtonPage={onButtonPage}
                    />
                </Grid>
            </Grid>
            : <></>
    );
};

const mapStateToProps = (state) => ({
    auth: state.auth
});

export default connect(mapStateToProps)(withStyles(useStyles)(Home));
