// redux
import {applyMiddleware, createStore} from 'redux';
import reducers from './reducers';

// redux-persist
import {persistStore, persistReducer} from 'redux-persist';
import storage from 'redux-persist/lib/storage';

// redux-saga
import createSagaMiddleware from 'redux-saga';
import rootSaga from "./sagas/rootSaga";

const persistConfig = {
    storage,
    key: 'ecosms'
};

const persistedReducer = persistReducer(persistConfig, reducers)

const sagaMiddleware = createSagaMiddleware();

export const store = createStore(
    persistedReducer,
    applyMiddleware(sagaMiddleware)
);

sagaMiddleware.run(rootSaga);

export const persistor = persistStore(store);
