import React, {useEffect, useState} from 'react';
import {isMobile, isMobileOnly} from 'react-device-detect';
import {FormattedMessage} from 'react-intl';
import Media from 'react-media';
import {connect} from 'react-redux';

import {
    AppBar,
    Button, ButtonGroup,
    Checkbox,
    Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle,
    Divider,
    FormControl, FormControlLabel,
    Grid,
    IconButton,
    InputAdornment, InputLabel,
    MenuItem,
    Select,
    TextField,
    Toolbar,
    Typography,
    withStyles
} from '@material-ui/core';
import {
    ArrowBackIos,
    ArrowForwardIos,
    CheckBoxOutlineBlankTwoTone,
    CheckBoxTwoTone,
    Close,
    Favorite,
    FavoriteBorder
} from '@material-ui/icons';
import {Autocomplete} from '@material-ui/lab';

import match from 'autosuggest-highlight/match';
import parse from 'autosuggest-highlight/parse';

import FeedStallChart from './FeedStallChart';

import EditButtonGroup from './EditButtonGroup';
import ProgressDialog from './ProgressDialog';

import fetchRest from '../util/fetch';

const FeedStallDetail = (props) => {
    // 상수값 정의
    const {autoConvertMode, bcsInfo, classes, stalls, stallTotalCount} = props;
    const prev_state = {};

    // state 정의
    const [confirmDialog, setConfirmDialog] = useState(false);
    const [confirmDialogText, setConfirmDialogText] = useState('');
    const [currentStallNo, setCurrentStallNo] = useState(props.selectedStallNo || props.match.params.stallNo);
    const [dataFetched, setDataFetched] = useState(false);
    const [loading, setLoading] = useState(false);
    const [readOnly, setReadOnly] = useState(true);

    const [deviceInfo, setDeviceInfo] = useState(undefined);
    const [feedInfo, setFeedInfo] = useState(undefined);
    const [stallInfo, setStallInfo] = useState({'STALL_STATE': 1});

    const [pigNameList, setPigNameList] = useState(undefined);

    // 데이터 요청
    useEffect(function () {
        const getPigInfo = () => {
            return fetchRest(`/pig_info?farm_code=${props.auth.farmCode}&pigsty_code=${props.auth.pigstyCode}`)
                .then((data) => {
                    return data;
                });
        };
        const getStallInfo = () => {
            return fetchRest(`/stall_info/${currentStallNo}?farm_code=${props.auth.farmCode}&pigsty_code=${props.auth.pigstyCode}`)
                .then((data) => {
                    return data;
                });
        };
        const promises = Promise.all([getPigInfo, getStallInfo]);
        promises.then(([pigInfoData, stallInfoData]) => {
            setDeviceInfo(stallInfoData['device_info']);
            setFeedInfo(stallInfoData['feed_info']);
            setPigNameList(pigInfoData['pig_name_list']);
            setStallInfo(stallInfoData['stall_info']);
        }).catch(() => {
            // 리덕스로 관리하는 다이알로그로 바꾸기(혜섭)
        });
    }, [])

    // 데이터가 모두 받아진 후에 dataFetched state 수정
    useEffect(function () {
        if (deviceInfo && feedInfo && pigNameList && stallInfo) {
            setDataFetched(true);
        }
    }, [deviceInfo, feedInfo, pigNameList, stallInfo]);

    const calcDate = (date, days) => {
        if (!(isNaN(date.getDate() + days))) {
            date.setDate(date.getDate() + days);
            return date.toISOString().substr(0, 10);
        }
    };

    // 이 부분 같이 작업하기
    const confirmModal = (option) => {

    };

    // 함수형으로 바꾸면서 좀 골치
    const editCancel = () => {
        setReadOnly(true);
    };

    // 여기도 나중에 같이 고치기
    const editStall = () => {
        setReadOnly(false);
    };

    const handleAutoComplete = (eventType) => (event, value) => {
        let stallInfo = stallInfo;
        if (eventType === 'select' && value && event) {
            pigNameList.map((pigName) => {
                if (pigName.PIG_NAME === value) {
                    stallInfo['BCS_CODE'] = pigName.BCS_CODE;
                    stallInfo['DELIVERY_COUNT'] = pigName.DELIVERY_COUNT;
                    stallInfo['PIG_NAME'] = pigName.PIG_NAME;
                    stallInfo['PLAN_TYPE'] = pigName.PLAN_TYPE;
                    setStallInfo({stallInfo: stallInfo});
                }
                return null;
            });
        } else if (eventType === 'text') {
            if (event && event.target.value) {
                stallInfo['PIG_NAME'] = event.target.value;
                pigNameList.map((pigName) => {
                    if (pigName.PIG_NAME === value) {
                        stallInfo['BCS_CODE'] = pigName.BCS_CODE;
                        stallInfo['DELIVERY_COUNT'] = pigName.DELIVERY_COUNT;
                        stallInfo['PLAN_TYPE'] = pigName.PLAN_TYPE;
                    }
                    return null;
                });
                setStallInfo({stallInfo: stallInfo});
            }
        }
    };

    // handleInput으로 다 관리할지 useEffect로 할지 정해야(호정)
    const handleInput = () => {

    };

    const oneTimeFeeding = () => {
        setLoading(true);

        fetchRest(`/one_time_feeding/${currentStallNo}?farm_code=${props.auth.farmCode}&pigsty_code=${props.auth.pigstyCode}`)
            .then((data) => {

            })
            .catch(() => {

            });
    };

    const moveStall = (idx) => {
        setCurrentStallNo(idx, () => {
            fetchRest(`/stall_info/${currentStallNo}?farm_code=${props.auth.farmCode}&pigsty_code=${props.auth.pigstyCode}`)
                .then((data) => {
                    setDeviceInfo(data['device_info']);
                    setFeedInfo(data['feed_info']);
                    setStallInfo(data['stall_info']);
                    // dataFetched 고칠 필요가 있나?
                })
                .catch(() => {
                    // 리덕스로 관리하는 다이알로그로 바꾸기
                });
        });
    };

    const updateStallInfo = () => {

    };

    const validate = () => {
        const BCSRate = stallInfo.BCS_RATE !== '' || stallInfo.BCS_RATE > 0;
        const deliveryCount = stallInfo.DELIVERY_COUNT !== '' || stallInfo.DELIVERY_COUNT > 1;
        const deliveryDate = stallInfo.DELIVERY_DATE !== '';
        const processDay = stallInfo.PROCESS_DAY !== '' || stallInfo.PROCESS_DAY !== 0;

        return BCSRate && deliveryCount && deliveryDate && processDay;
    };

    if (dataFetched) {
        return (
            <>
                {
                    isMobileOnly &&
                    <AppBar position='relative' color='default'>
                        <Toolbar>
                            <IconButton edge='start' onClick={props.closeStallDialog}
                                        className={classes.mobileDialogClose}>
                                <Close/>
                            </IconButton>
                        </Toolbar>
                    </AppBar>
                }
                <Grid alignItems='center'
                      className={isMobileOnly ? 'dialog-stall-mobile' : 'container-stall'}
                      container
                      justify='center'>
                    <Grid alignItems='center'
                          className='dialog-header--stall dialog-stall--row'
                          container
                          item
                          justify='center'
                          xs={12}
                          spacing={1}>
                        {
                            !isMobileOnly &&
                            <Grid alignItems='center' container item justify='flex-start' spacing={1} xs={4}>
                                {
                                    !isMobile &&
                                    <Grid item xs={1}/>
                                }
                                <Grid item md={5} sm={6}>
                                    <FormControlLabel
                                        control={<Checkbox
                                            onChange={this.handleInput('TODAY_FASTING')}
                                            checked={stallInfo['TODAY_FASTING'] === 2}
                                            checkedIcon={<CheckBoxTwoTone color='secondary' fontSize='small'/>}
                                            icon={<CheckBoxOutlineBlankTwoTone fontSize='small'/>}/>}
                                        disabled={readOnly || stallInfo.STALL_STATE === 1}
                                        id='TODAY_FASTING'
                                        label={<FormattedMessage id='feedStallFasting'/>}
                                        labelPlacement='start'
                                        name='TODAY_FASTING'/>
                                </Grid>
                                <Grid item md={5} sm={6}>
                                    <FormControlLabel
                                        control={<Checkbox
                                            onChange={this.handleInput('AUTO_CONVERT_MODE')}
                                            checked={stallInfo['AUTO_CONVERT_MODE'] === 2}
                                            checkedIcon={<Favorite fontSize='small'/>}
                                            icon={<FavoriteBorder fontSize='small'/>}/>}
                                        disabled={readOnly || stallInfo.STALL_STATE === 1}
                                        id='AUTO_CONVERT_MODE'
                                        label={<FormattedMessage id='feedStallAutoConverting'/>}
                                        labelPlacement='start'
                                        name='AUTO_CONVERT_MODE'/>
                                </Grid>
                            </Grid>
                        }
                        <Grid alignItems='center'
                              container
                              item
                              justify='center'
                              sm={4}
                              xs={4}>
                            {
                                !isMobileOnly &&
                                <Grid container item justify='center' xs={1}>
                                    <Grid item>
                                        <ArrowBackIos
                                            className={((-1 < stalls.indexOf(currentStallNo) - 1) && readOnly) ? null : classes.moveArrowInvisible}
                                            onClick={() => {
                                                const idx = stalls.indexOf(currentStallNo) - 1;
                                                if (-1 < idx) {
                                                    this.moveStall(stalls[idx]);
                                                }
                                            }}/>
                                    </Grid>
                                </Grid>
                            }
                            <Grid container item sm={4} xs={10}
                                  direction='row'
                                  justify={isMobileOnly ? 'flex-start' : 'center'}>
                                <Grid item style={{width: '100%'}}>
                                    <Typography variant='h5' style={{textAlign: 'center'}}><FormattedMessage
                                        id='feedStallStall' values={{stallNo: currentStallNo}}/></Typography>
                                </Grid>
                                <Grid item>
                                    <Typography color='primary' className={classes.stallCode}>
                                        {deviceInfo.R_S_CODE} / {deviceInfo.DCM_CODE}
                                    </Typography>
                                </Grid>
                            </Grid>
                            {
                                !isMobileOnly &&
                                <Grid container item justify='center' xs={1}>
                                    <Grid item>
                                        <ArrowForwardIos
                                            className={((stalls.indexOf(currentStallNo) + 1 < stallTotalCount) && readOnly) ? null : classes.moveArrowInvisible}
                                            onClick={() => {
                                                const idx = stalls.indexOf(currentStallNo) + 1;
                                                if (idx < stallTotalCount) {
                                                    moveStall(stalls[idx]);
                                                }
                                            }}/>
                                    </Grid>
                                </Grid>
                            }
                        </Grid>
                        <Grid alignItems='center' container item justify='flex-end' sm={4} spacing={1} xs={8}>
                            {
                                !isMobileOnly ?
                                    <>
                                        <Grid item>
                                            <Button size='medium'
                                                    variant='outlined'
                                                    onClick={oneTimeFeeding}
                                                    className={classes.stallButtonGroup}
                                                    disabled={!(readOnly)}>
                                                {<FormattedMessage id='feedStallFeeding'/>}
                                            </Button>
                                        </Grid>
                                        <Grid item>
                                            <EditButtonGroup additional={props.closeStallDialog}
                                                             additionalText={<FormattedMessage id='commonClose'/>}
                                                             condition={readOnly ? 'additional' : 'editDone'}
                                                             editCancel={editCancel}
                                                             editData={editStall}
                                                             readOnly={readOnly}
                                                             updateData={updateStallInfo}/>
                                        </Grid>
                                        <Grid item xs={1}/>
                                    </> :
                                    <>
                                        <Grid item>
                                            <EditButtonGroup buttonSize='small'
                                                             condition={readOnly ? 'edit' : 'editDone'}
                                                             editCancel={editCancel}
                                                             editData={editStall}
                                                             readOnly={readOnly}
                                                             updataData={updateStallInfo}/>
                                        </Grid>
                                        {
                                            readOnly &&
                                            <Grid item>
                                                <ButtonGroup>
                                                    <Button onClick={() => {
                                                        moveStall(0 < currentStallNo - 1 ? currentStallNo - 1 : 1);
                                                    }}
                                                            size='small'
                                                            className={classes.stallButtonGroup}
                                                            variant='outlined'>
                                                        {<FormattedMessage id='commonPrev'/>}
                                                    </Button>
                                                    <Button onClick={() => {
                                                        moveStall(currentStallNo + 1 < stallTotalCount ? currentStallNo + 1 : stallTotalCount);
                                                    }}
                                                            size='small'
                                                            className={classes.stallButtonGroup}
                                                            variant='outlined'>
                                                        {<FormattedMessage id='commonNext'/>}
                                                    </Button>
                                                </ButtonGroup>
                                            </Grid>
                                        }
                                    </>
                            }
                        </Grid>
                    </Grid>

                    <Grid container item xs={12}
                          alignItems='center'
                        // alignItems={isMobileOnly ? 'flex-start' : 'center'}
                          className='dialog-stall--row'
                          direction={isMobileOnly ? 'column' : null}
                          justify='flex-start'
                          spacing={isMobileOnly ? 1 : 2}>
                        {
                            !isMobileOnly &&
                            <Grid item xs={2}>
                                <Typography align='center'>{<FormattedMessage id='feedStallStallInfo'/>}</Typography>
                            </Grid>
                        }
                        <Grid container
                              item
                              spacing={isMobileOnly ? 1 : 2}
                              sm={10}
                              xs={12}>
                            <Grid item
                                  sm={3}
                                  xs={6}>
                                <FormControl disabled={readOnly}
                                             fullWidth
                                             margin='normal'>
                                    <InputLabel shrink>{<FormattedMessage id='feedStallStallState'/>}</InputLabel>
                                    <Select onChange={handleInput('STALL_STATE')}
                                            value={stallInfo.STALL_STATE}>
                                        <MenuItem key={1} value={1}>{<FormattedMessage
                                            id='commonEmptyRoom'/>}</MenuItem>
                                        <MenuItem key={2} value={2}
                                                  disabled={prev_state.stallInfo.STALL_STATE === 3}>{
                                            <FormattedMessage id='commonFormation'/>}</MenuItem>
                                        <MenuItem key={3} value={3}>{<FormattedMessage id='commonDelivery'/>}</MenuItem>
                                    </Select>
                                </FormControl>
                            </Grid>
                            <Grid item
                                  sm={3}
                                  xs={6}>
                                {
                                    pigNameList.length > 0 ?
                                        <Autocomplete autoComplete
                                                      freeSolo
                                            // getOptionLabel={option => option.PIG_NAME}
                                                      id="autocomplete--pigname"
                                                      onChange={handleAutoComplete('select')}
                                                      onInputChange={handleAutoComplete('text')}
                                                      options={pigNameList.map(option => option.PIG_NAME)}
                                                      renderInput={params => (
                                                          <TextField {...params}
                                                                     fullWidth
                                                                     InputLabelProps={{shrink: true}}
                                                                     label={<FormattedMessage id='feedStallPigName'/>}
                                                                     margin='normal'/>
                                                      )}
                                                      renderOption={(option, {inputValue}) => {
                                                          const matches = match(option, inputValue);
                                                          const parts = parse(option, matches);

                                                          return (
                                                              <div>
                                                                  {parts.map((part, index) => (
                                                                      <span key={index}
                                                                            style={{fontWeight: part.highlight ? 700 : 400}}>
                                                                          {part.text}
                                                                      </span>
                                                                  ))}
                                                              </div>
                                                          );
                                                      }}
                                                      value={stallInfo.PIG_NAME}
                                                      disabled={readOnly || stallInfo.STALL_STATE === 1}/> :
                                        <TextField fullWidth
                                                   InputLabelProps={{
                                                       shrink: true,
                                                   }}
                                                   InputProps={{
                                                       readOnly: readOnly,
                                                   }}
                                                   label={<FormattedMessage id='feedStallPigName'/>}
                                                   margin='normal'
                                                   onChange={handleInput('PIG_NAME')}
                                                   value={stallInfo.PIG_NAME}
                                                   disabled={stallInfo.STALL_STATE === 1}/>
                                }
                            </Grid>
                            <Grid item
                                  sm={2}
                                  xs={6}>
                                <TextField className='input--text-center'
                                           fullWidth
                                           InputLabelProps={{
                                               shrink: true,
                                           }}
                                           InputProps={{
                                               readOnly: readOnly,
                                               inputProps: {min: 1}
                                           }}
                                           label={<FormattedMessage id='feedStallDeliveryCount'/>}
                                           margin='normal'
                                           onChange={handleInput('DELIVERY_COUNT')}
                                           type='number'
                                           value={stallInfo.DELIVERY_COUNT}
                                           disabled={stallInfo.STALL_STATE === 1}/>
                            </Grid>
                            {
                                !isMobileOnly &&
                                <Grid item sm={2}>
                                    <FormControl disabled={readOnly || stallInfo.STALL_STATE === 1}
                                                 fullWidth
                                                 margin='normal'>
                                        <InputLabel shrink>{<FormattedMessage id='feedStallBCSCode'/>}</InputLabel>
                                        <Select className='input--text-center'
                                                onChange={handleInput('BCS_CODE')}
                                                value={stallInfo.BCS_CODE}>
                                            {
                                                Array(5).fill(1).map((value, index) => {
                                                    const idx = index + 1;
                                                    return <MenuItem key={`n${idx}`} value={idx}>{idx}</MenuItem>
                                                })
                                            }
                                        </Select>
                                    </FormControl>
                                </Grid>
                            }
                            <Grid item
                                  sm={2}
                                  xs={6}>
                                {
                                    readOnly
                                        ? <TextField className='input--text-center'
                                                     fullWidth
                                                     InputLabelProps={{
                                                         shrink: true,
                                                     }}
                                                     label={<FormattedMessage id='feedStallProcessDay'/>}
                                                     margin='normal'
                                                     type='number'
                                                     value={stallInfo.PROCESS_DAY}/>
                                        : <FormControl disabled={readOnly || stallInfo.STALL_STATE === 1}
                                                       fullWidth
                                                       margin='normal'>
                                            <InputLabel shrink>{<FormattedMessage id='feedStallProcessDay'/>}</InputLabel>
                                            <Select className='input--py-10 input--text-center'
                                                    onChange={handleInput('PROCESS_DAY')}
                                                    value={stallInfo.PROCESS_DAY}>
                                                {
                                                    Array(stallInfo.STALL_STATE === 2 ? 15 : 35).fill(1).map((value, index) => {
                                                        if (stallInfo.STALL_STATE === 2) {
                                                            return <MenuItem key={index}
                                                                             value={index - 15}>{index - 15}</MenuItem>;
                                                        } else {
                                                            return <MenuItem key={index}
                                                                             value={index + 1}>{index + 1}</MenuItem>;
                                                        }
                                                    })
                                                }
                                            </Select>
                                        </FormControl>
                                }
                            </Grid>
                        </Grid>
                    </Grid>

                    <Grid alignItems='center'
                          className='dialog-stall--row'
                          container
                          direction={isMobileOnly ? 'column' : null}
                          item
                          justify='flex-start'
                          spacing={isMobileOnly ? 1 : 2}
                          xs={12}>
                        {
                            !isMobileOnly &&
                            <Grid item xs={2}>
                                <Typography align='center'></Typography>
                            </Grid>
                        }
                        <Grid container
                              item
                              sm={10}
                              spacing={isMobileOnly ? 1 : 2}
                              xs={12}>

                            <Grid item sm={3} xs={6}>
                                <FormControl disabled={readOnly || stallInfo.STALL_STATE === 1}
                                             fullWidth
                                             margin='normal'>
                                    <InputLabel shrink>{<FormattedMessage id='feedStallPlanType'/>}</InputLabel>
                                    <Select className='input--text-center'
                                            onChange={handleInput('PLAN_TYPE')}
                                            value={stallInfo.PLAN_TYPE}>
                                        {
                                            Array(6).fill(1).map((value, index) => {
                                                const idx = index + 1;
                                                return <MenuItem key={`n${idx}`} value={idx}>{idx} 유형</MenuItem>
                                            })
                                        }
                                    </Select>
                                </FormControl>
                            </Grid>
                            <Grid item sm={3} xs={6}>
                                <TextField className='input--text-center'
                                           fullWidth
                                           InputLabelProps={{
                                               shrink: true,
                                           }}
                                           InputProps={{
                                               readOnly: readOnly,
                                               inputProps: {step: 5},
                                               endAdornment: <InputAdornment position="end">%</InputAdornment>,
                                           }}
                                           label={<FormattedMessage id='feedStallBCSRate'/>}
                                           margin='normal'
                                           onChange={handleInput('BCS_RATE')}
                                           type='number'
                                           value={stallInfo.BCS_RATE}
                                           disabled={stallInfo.STALL_STATE === 1}/>
                            </Grid>
                            {
                                !isMobileOnly &&
                                <>
                                    <Grid item sm={3}>
                                        <TextField className='input--text-center'
                                                   fullWidth
                                                   InputLabelProps={{
                                                       shrink: true,
                                                   }}
                                                   InputProps={{
                                                       readOnly: readOnly,
                                                   }}
                                                   label={<FormattedMessage id='feedStallCrossDate'/>}
                                                   margin='normal'
                                                   onChange={handleInput('CROSS_DATE')}
                                                   type='date'
                                                   value={stallInfo.CROSS_DATE}
                                                   disabled={stallInfo.STALL_STATE === 1}/>
                                    </Grid>
                                    <Grid item sm={3}>
                                        <TextField className='input--text-center'
                                                   fullWidth
                                                   InputLabelProps={{
                                                       shrink: true,
                                                   }}
                                                   InputProps={{
                                                       readOnly: readOnly,
                                                   }}
                                                   label={<FormattedMessage id='feedStallDeliveryDate'/>}
                                                   margin='normal'
                                                   onChange={handleInput('DELIVERY_DATE')}
                                                   type='date'
                                                   value={stallInfo.DELIVERY_DATE}
                                                   disabled={stallInfo.STALL_STATE === 1}/>
                                    </Grid>
                                </>
                            }
                        </Grid>
                    </Grid>

                    <Grid alignItems={isMobileOnly ? 'flex-start' : 'center'}
                          className='dialog-stall--row'
                          container
                          direction={isMobileOnly ? 'column' : null}
                          item
                          justify='flex-start'
                          spacing={isMobileOnly ? 1 : 2}
                          xs={12}>
                        {
                            !isMobileOnly &&
                            <Grid item sm={2} xs={12}>
                                <Typography align='center'>{<FormattedMessage id='feedStallDeliveryInfo'/>}</Typography>
                            </Grid>
                        }
                        <Grid container
                              item
                              sm={10}
                              spacing={isMobileOnly ? 1 : 2}
                              xs={12}>
                            <Grid item sm={3} xs={6}>
                                <TextField className='input--text-center'
                                           fullWidth
                                           InputLabelProps={{
                                               shrink: true,
                                           }}
                                           InputProps={{
                                               readOnly: readOnly,
                                               inputProps: {min: 0, max: 20},
                                           }}
                                           label={<FormattedMessage id='feedStallLitterSize'/>}
                                           margin='normal'
                                           type='number'
                                           onChange={handleInput('LITTER_SIZE')}
                                           value={stallInfo.LITTER_SIZE}
                                           disabled={stallInfo.STALL_STATE === 1}/>
                            </Grid>
                            <Grid item sm={3} xs={6}>
                                <TextField className='input--text-center'
                                           fullWidth
                                           InputLabelProps={{
                                               shrink: true,
                                           }}
                                           InputProps={{
                                               readOnly: readOnly,
                                               inputProps: {min: 0, max: 20},
                                           }}
                                           label={<FormattedMessage id='feedStallPigletCount'/>}
                                           margin='normal'
                                           type='number'
                                           onChange={handleInput('PIGLET_COUNT')}
                                           value={stallInfo.PIGLET_COUNT}
                                           disabled={stallInfo.STALL_STATE === 1}/>
                            </Grid>
                            {
                                !isMobileOnly &&
                                <Grid item xs={3}>
                                    <TextField className='input--text-center'
                                               fullWidth
                                               InputLabelProps={{
                                                   shrink: true,
                                               }}
                                               InputProps={{
                                                   readOnly: readOnly,
                                                   inputProps: {min: 0, max: 20, step: 0.1},
                                                   endAdornment: <InputAdornment position="end">kg</InputAdornment>,
                                               }}
                                               label={<FormattedMessage id='feedStallBirthWeight'/>}
                                               margin='normal'
                                               type='number'
                                               onChange={handleInput('AVG_BIRTH_WEIGHT')}
                                               value={stallInfo.AVG_BIRTH_WEIGHT}
                                               disabled={stallInfo.STALL_STATE === 1}/>
                                </Grid>
                            }
                            {
                                !isMobileOnly &&
                                <Grid item xs={3}>
                                    <TextField className='input--text-center'
                                               fullWidth
                                               InputLabelProps={{
                                                   shrink: true,
                                               }}
                                               InputProps={{
                                                   readOnly: readOnly,
                                                   inputProps: {min: 0, max: 20, step: 0.1},
                                                   endAdornment: <InputAdornment position="end">kg</InputAdornment>,
                                               }}
                                               label={<FormattedMessage id='feedStallPigletWeight'/>}
                                               margin='normal'
                                               type='number'
                                               onChange={handleInput('AVG_PIGLET_WEIGHT')}
                                               value={stallInfo.AVG_PIGLET_WEIGHT}
                                               disabled={stallInfo.STALL_STATE === 1}/>
                                </Grid>
                            }
                            <Divider/>
                        </Grid>
                    </Grid>
                    {
                        !isMobileOnly &&
                        <Grid container item justify='center' spacing={1} xs={12}>
                            <Grid item xs={11}>
                                {
                                    feedInfo &&
                                    <FeedStallChart feedIntake={feedInfo}/>
                                }
                            </Grid>
                        </Grid>
                    }
                    {
                        isMobileOnly &&
                        <Grid className='contents--margin-top_10' container item justify='space-evenly' spacing={2}
                              xs={12}>
                            <Grid container item justify='center' xs={6}>
                                <Grid item>
                                    <FormControlLabel
                                        control={<Checkbox
                                            onChange={handleInput('TODAY_FASTING')}
                                            checked={stallInfo['TODAY_FASTING'] === 2}
                                            checkedIcon={<CheckBoxTwoTone color='secondary'/>}
                                            icon={<CheckBoxOutlineBlankTwoTone/>}/>}
                                        disabled={readOnly || stallInfo.STALL_STATE === 1}
                                        id='TODAY_FASTING'
                                        label={<FormattedMessage id='feedStallFasting'/>}
                                        labelPlacement='end'
                                        name='TODAY_FASTING'/>
                                </Grid>
                            </Grid>
                            <Grid container item justify='center' xs={6}>
                                <Grid item>
                                    <FormControlLabel
                                        control={<Checkbox
                                            onChange={handleInput('AUTO_CONVERT_MODE')}
                                            checked={stallInfo['AUTO_CONVERT_MODE'] === 2}
                                            checkedIcon={<Favorite/>}
                                            icon={<FavoriteBorder/>}/>}
                                        disabled={readOnly || stallInfo.STALL_STATE === 1}
                                        id='AUTO_CONVERT_MODE'
                                        label={<FormattedMessage id='feedStallAutoConverting'/>}
                                        labelPlacement='end'
                                        name='AUTO_CONVERT_MODE'/>
                                </Grid>
                            </Grid>
                            <Grid item xs={11}>
                                <Button color='secondary'
                                        fullWidth size='large'
                                        variant='contained'
                                        onClick={oneTimeFeeding}
                                        disabled={!(readOnly)}>
                                    <Typography variant='body2'>{<FormattedMessage id='feedStallFeeding'/>}</Typography>
                                </Button>
                            </Grid>
                        </Grid>
                    }
                    {
                        isMobileOnly &&
                        <Media query="(min-width: 600px) and (max-width: 960px)" render={() =>
                            (
                                <Grid container item justify='center' spacing={1} xs={12} style={{marginTop: '10px'}}>
                                    {
                                        feedInfo &&
                                        <FeedStallChart feedIntake={feedInfo}/>
                                    }
                                </Grid>
                            )}
                        />
                    }
                </Grid>

                {/*리덕스로 옮기기*/}
                {/*<AlertDialog*/}
                {/*    alertText={state.alertDialogText}*/}
                {/*    handleEditAlertClose={this.alertDialogClose}*/}
                {/*    openEditAlert={state.alertDialog}/>*/}

                <Dialog open={confirmDialog} aria-labelledby="responsive-dialog-title">
                    <DialogTitle id="responsive-dialog-title">{<FormattedMessage id='feedStallSetEmpty'/>}</DialogTitle>
                    <DialogContent>
                        <DialogContentText>
                            {confirmDialogText}
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={() => confirmModal('cancel')} color="primary">
                            취소
                        </Button>
                        <Button onClick={() => confirmModal('reset')} color="primary"
                                autoFocus={prev_state.stallInfo.STALL_STATE === 2}>
                            {prev_state.stallInfo.STALL_STATE === 2 ? <FormattedMessage id='commonConfirm'/> :
                                <FormattedMessage id='commonCancel'/>}
                        </Button>
                        {
                            prev_state.stallInfo.STALL_STATE === 3 ?
                                <Button onClick={() => confirmModal('save')} color="primary" autoFocus>
                                    {<FormattedMessage id='commonSave'/>}
                                </Button>
                                : null
                        }
                    </DialogActions>
                </Dialog>

                <ProgressDialog loading={loading}/>
            </>
        );
    } else {
        return (<></>);
    }
};

const mapStateToProps = (state) => ({
    auth: state.auth
});

export default connect(mapStateToProps)(FeedStallDetail);
