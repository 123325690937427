import React, {useState} from 'react';
import {connect} from 'react-redux';

import {Avatar, Button, Grid, TextField, Typography} from '@material-ui/core';
import {LockOutlined} from '@material-ui/icons';

import * as actions from '../actions';
import AlertDialog from '../common/AlertDialog';
import fetchRest from '../util/fetch';

const Copyright = () => {
    return (
        <Typography align='center' color='textSecondary' variant='body2'>
            {'Copyright © Ecosystem Inc.'}
            {' '}
            {new Date().getFullYear()}
            {'.'}
        </Typography>
    );
};

const LogIn = (props) => {
    const [alert, setAlert] = useState(false);
    const [id, setId] = useState('');
    const [password, setPassword] = useState('');

    const login = () => {
        const loginInput = Object.assign({}, {id: id, pw: password});

        fetchRest('/login', {
            body: JSON.stringify(loginInput),
            method: 'POST'
        }).then((data) => {
            props.setAuth(data);
        }).catch(() => {
            props.revokeAuth();
        });
    };

    return (
        <>
            <AlertDialog alert={alert} alertText={'등록된 ID가 없거나 비밀번호가 맞지 않습니다.\n다시 시도해주세요.'} setAlert={setAlert}/>
            <Grid container justify='center'>
                <Grid alignItems='center' container direction='row' item justify='center' spacing={1} xl={3} xs={6}>
                    <Grid alignItems='center' container item justify='flex-start' xs={12}>
                        <Grid item>
                            <Avatar>
                                <LockOutlined/>
                            </Avatar>
                        </Grid>
                        <Grid item>
                            <Typography component='h1' variant='h5'>
                                로그인
                            </Typography>
                        </Grid>
                    </Grid>
                    <Grid item xs={12}>
                        <TextField fullWidth
                                   id='id'
                                   label='아이디'
                                   name='id'
                                   onChange={(e) => setId(e.target.value)}
                                   value={id}
                                   variant='outlined'/>
                    </Grid>
                    <Grid item xs={12}>
                        <TextField fullWidth
                                   id='password'
                                   label='비밀번호'
                                   name='password'
                                   onChange={(e) => setPassword(e.target.value)}
                                   value={password}
                                   variant='outlined'/>
                    </Grid>
                    <Grid item xs={12}>
                        <Button color='primary'
                                disabled={(!id || !password)}
                                fullWidth
                                onClick={login}
                                variant='contained'>
                            로그인
                        </Button>
                    </Grid>
                    <Grid item>
                        <Copyright/>
                    </Grid>
                </Grid>
            </Grid>
        </>
    );
};

const mapDispatchToProps = (dispatch) => ({
    revokeAuth: () => {
        dispatch(actions.revokeAuth());
    },
    setAuth: (auth) => {
        dispatch(actions.setAuth(auth));
    }
});

const mapStateToProps = (state) => ({
    auth: state.auth
});

export default connect(mapStateToProps, mapDispatchToProps)(LogIn);