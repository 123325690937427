import * as types from '../actions/ActionTypes';

const initialState = {
    alert: false,
    alertText: ''
};

const alert = (state = initialState, action) => {
    switch (action.type) {
        case types.CLOSE_ALERT:
            return {...initialState};
        case types.OPEN_ALERT:
            return {...action.alert};
        default:
            return state;
    }
};

export default alert;
