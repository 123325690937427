import React from 'react';
import {isMobileOnly} from 'react-device-detect';
import {connect} from 'react-redux';

import {AppBar, Button, Grid, IconButton, Toolbar} from '@material-ui/core';
import {ExitToApp} from '@material-ui/icons';
import {withStyles} from '@material-ui/styles';

import * as actions from '../actions';
import history from '../history';
import {ReactComponent as Logo} from '../logo-ecosystem.svg';

const useStyles = {};

const AppBarEco = (props) => {
    const {auth} = props;
    const {revokeAuth} = props;

    return (
        <AppBar color='default' position='static'>
            <Toolbar>
                <Grid container>
                    <Grid item md={6} xs={12}>
                        <Logo className='main-logo'
                              onClick={() => {
                                  history.push('/')
                              }}/>
                        {
                            isMobileOnly && auth.farmCode &&
                            <IconButton onClick={revokeAuth}>
                                <ExitToApp/>
                            </IconButton>
                        }
                    </Grid>
                </Grid>
                {
                    auth.farmCode &&
                    <>
                        <Grid item xs={3}>
                            <Button onClick={(e) => history.push('/home')} variant='text'>
                                스톨 현황
                            </Button>
                        </Grid>
                        <Grid item xs={3}>
                            <Button onClick={(e) => history.push('/feed_stall')} variant='text'>
                                모돈 현황
                            </Button>
                        </Grid>
                        <Grid item xs={3}>
                            <Button onClick={(e) => history.push('/feed_time')} variant='text'>
                                시간 설정
                            </Button>
                        </Grid>
                        <Grid item xs={3}>
                            <Button onClick={(e) => history.push('/feed_plan')} variant='text'>
                                유형 설정
                            </Button>
                        </Grid>
                    </>
                }
                {
                    !isMobileOnly &&
                        <IconButton onClick={props.revokeAuth}>
                            <ExitToApp/>
                        </IconButton>
                }
            </Toolbar>
        </AppBar>
    );
};

const mapDispatchToProps = (dispatch) => ({
    revokeAuth: () => {
        dispatch(actions.revokeAuth());
    }
});

const mapStateToProps = (state) => ({
    auth: state.auth
});

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(useStyles)(AppBarEco));
