import React, {useEffect, useState} from 'react';
import {isMobile} from 'react-device-detect';
import {FormattedMessage} from 'react-intl';
import {connect} from 'react-redux';

import {Grid, FormControl, MenuItem, Select, Typography} from '@material-ui/core';

import EditButtonGroup from '../common/EditButtonGroup';

const FeedTime = (props) => {
    const [division, setDivision] = useState(undefined);
    const [readOnly, setReadOnly] = useState(true);

    // 비즈니스 로직
    useEffect(function () {

    }, [division]);

    return (
        <>
            <Grid container direction='column'>
                <Grid item>
                    {/*함수 추가해야*/}
                    <EditButtonGroup condition={readOnly ? 'edit' : 'editDone'}
                                     readOnly={readOnly}/>
                </Grid>
                <Grid container item>
                    <Grid item>
                        <Typography>
                            <FormattedMessage id='feedTimeCount'/>
                        </Typography>
                    </Grid>
                    <Grid item>
                        <FormControl disabled={readOnly} margin={isMobile ? 'dense' : 'normal'}>
                            <Select inputProps={{
                                id: 'selected-type',
                                name: 'selected_type',
                                readOnly: readOnly
                            }}
                                    onChange={(e) => setDivision(e.target.value)}
                                    value={division}>
                                {
                                    Array(6).fill(1).map((value, index) => {
                                        return <MenuItem key={`n${index + 1}`} value={index + 1}>
                                            {index + 1} <FormattedMessage id='commonCount'/>
                                        </MenuItem>;
                                    })
                                }
                            </Select>
                        </FormControl>
                    </Grid>
                </Grid>
            </Grid>
        </>
    );
};

const mapStateToProps = (state) => ({
    alert: state.alert,
    auth: state.auth
});

export default connect(mapStateToProps)(FeedTime);
