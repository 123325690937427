import React from 'react';

import {CircularProgress, Dialog} from '@material-ui/core';
import {withStyles} from '@material-ui/core/styles';

const useStyles = {
    dialog: {
        animation: 'none',
        padding: '20px'
    }
};

const ProgressDialog = (props) => {
    return (
        <Dialog open={props.loading}>
            <CircularProgress className={props.classes.dialog}/>
        </Dialog>
    );
};

export default (withStyles(useStyles)(ProgressDialog));
