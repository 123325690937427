import {isMobile} from 'react-device-detect';
import {connect} from 'react-redux';

import {Button, Dialog, DialogActions, DialogTitle, Typography} from '@material-ui/core';

import * as actions from '../actions';

const AlertDialog = (props) => {
    const {alert, alertText} = props.alert;
    return (
        <Dialog open={alert}>
            <DialogTitle>
                <Typography variant={isMobile ? 'body1' : 'h6'}>
                    {alertText}
                </Typography>
            </DialogTitle>
            <DialogActions>
                <Button autoFocus color='primary' onClick={(e) => props.closeAlert()}>
                    확인
                </Button>
            </DialogActions>
        </Dialog>
    );
};

const mapDispatchToProps = (dispatch) => ({
    closeAlert: () => {
        dispatch(actions.closeAlert());
    }
});

const mapStateToProps = (state) => ({
    alert: state.alert
});

export default connect(mapStateToProps, mapDispatchToProps)(AlertDialog);
