import {call, put, select, takeEvery} from 'redux-saga/effects';

import * as types from '../actions/ActionTypes';

function* fetchAuth(action) {
    try {
        const {payload} = action;

        // fetchRest로 바꿔야(error)
        const response = yield call(fetch, '??', {
                body: JSON.stringify(payload),
                method: 'POST'
            }
        );

        const data = yield response.json();

        yield put({type: types.FETCH_AUTH_SUCCESS, payload: data});
    } catch (error) {
        yield put({type: types.FETCH_AUTH_FAILED, error: error});
    }
}

export function* watchAuthSaga() {
    yield takeEvery(types.FETCH_AUTH, fetchAuth);
}