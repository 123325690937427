import React from 'react';
import {isMobile} from 'react-device-detect';

import {Button, Grid, IconButton, Typography} from '@material-ui/core';
import {NavigateBefore, NavigateNext} from '@material-ui/icons';

const Pagination = (props) => {
    const {endItem, maxItems, maxPage, page, startItem} = props;

    return (
        <Grid container item xs={12} justify={"flex-end"} alignItems={"center"}>
            {
                !isMobile &&
                <Grid item>
                    <Typography>{startItem} - {endItem} of {maxItems}</Typography>
                </Grid>
            }
            <Grid item>
                <IconButton onClick={props.onButtonBefore}><NavigateBefore/></IconButton>
            </Grid>
            <Grid item>
                {Array(maxPage).fill(0).map((value, index) => {
                    const idx = index + 1;
                    return (
                        <Button key={index}
                                style={{padding: 0, minWidth: 32}}
                                disabled={page === idx}
                                onClick={(e) => props.onButtonPage(idx)}>
                            {idx}
                        </Button>)
                })}
            </Grid>
            <Grid item>
                <IconButton onClick={props.onButtonNext}>
                    <NavigateNext/>
                </IconButton>
            </Grid>
        </Grid>
    );
};

export default Pagination;
