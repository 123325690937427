import React, {useEffect, useState} from 'react';

import {Grid, TextField, Typography} from '@material-ui/core';

const CreateUser = (props) => {
    const [farmAddress, setFarmAddress] = useState('');
    const [farmCode, setFarmCode] = useState('');
    const [farmName, setFarmName] = useState('');
    const [userID, setUserID] = useState('');
    const [userPW, setUserPW] = useState('');

    return (
        <Grid container direction='column' spacing={2}>
            <Grid item>
                <Typography>
                    농장 계정 생성
                </Typography>
            </Grid>
            <Grid item>
                <TextField id='userID'
                           onChange={(e) => setUserID(e.target.value)}
                           placeholder='아이디'
                           value={userID}
                           variant='outlined'/>
            </Grid>
            <Grid item>
                <TextField id='userPW'
                           onChange={(e) => setUserPW(e.target.value)}
                           placeholder='비밀번호'
                           value={userPW}
                           variant='outlined'/>
            </Grid>
            <Grid item>
                <TextField id='farmName'
                           onChange={(e) => setFarmName(e.target.value)}
                           placeholder='농장이름'
                           value={farmName}
                           variant='outlined'/>
            </Grid>
            <Grid item>
                <TextField id='farmCode'
                           onChange={(e) => setFarmCode(e.target.value)}/>
            </Grid>
        </Grid>
    );
};

export default CreateUser;
