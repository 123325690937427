export default {
    // Common
    commonCount: '',

    // Alert

    // EditButtonGroup
    commonCancle: '취소',
    commonComplete: '완료',
    commonEdit: '수정',

    // Components

    // FeedTime
    feedTimeClear: '',
    feedTimeCount: '',
    feedTimeDataUpdateFailed: '',
    feedTimeDelay: '',
    feedTimeFeedUnit: '',
    feedTimeTime: '',
    feedTimeRatio: '',
    feedTimeReset: '',
    feedTimeSecond: '',
    feedTimeSetAutoConverting: '',
    feedTimeWater: '',
    feedTimeWaterUnit: ''

};
