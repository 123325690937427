import React from 'react';
import Highcharts from 'highcharts/highstock';
import HighchartsReact from 'highcharts-react-official';

const FeedStallChart = (props) => {
    const option = {
        chartOptions: {
            chart: {
                height: '300px',
                zoomType: 'xy'
            },
            credits: {
                enabled: true
            },
            legend: {
                align: 'left',
                backgroundColor: 'rgba(255,255,255,0.25)',
                floating: true,
                layout: 'vertical',
                verticalAlign: 'top',
                x: 120,
                y: 5
            },
            series: [
                {
                    data: props.feedIntake[0].data,
                    name: props.feedIntake[0].name,
                    tooltip: {
                        valueSuffix: ' kg'
                    },
                    type: 'column'
                },
                {
                    data: props.feedIntake[1].data,
                    name: props.feedIntake[1].name,
                    tooltip: {
                        valueSuffix: ' kg',
                    },
                    type: 'spline'
                }
            ],
            title: {
                text: null
            },
            tooltip: {
                shared: true,
                valueDecimals: 1
            },
            xAxis: [{
                categories: props.feedIntake[2].xAxis,
                crosshair: true
            }],
            yAxis: [
                {
                    labels: {
                        format: '{value} kg'
                    },
                    title: {
                        text: null
                    },
                    max: 12,
                    min: 0
                }
            ]
        },
    };

    return (
        <HighchartsReact highcharts={Highcharts}
                         options={{
                             ...option,
                             series: [
                                 {
                                     data: props.feedIntake[0].data,
                                     name: props.feedIntake[0].name,
                                     tooltip: {
                                         valueSuffix: ' kg'
                                     },
                                     type: 'column'
                                 },
                                 {
                                     data: props.feedIntake[1].data,
                                     name: props.feedIntake[1].name,
                                     tooltip: {
                                         valueSuffix: ' kg'
                                     },
                                     type: 'spline'
                                 }
                             ],
                             xAxis: [
                                 {
                                     categories: props.feedIntake[2].xAxis,
                                     crosshair: true
                                 }
                             ]
                         }}

        />
    );
};

export default FeedStallChart;